import React from 'react';
import Accordion from '../utils/Accordion';

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">F.A.Q</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="Ais-je besoin de connaissances en codage pour utiliser Socoopt?">
              Non! Tout est géré par nous, nous intégrons votre logiciel à votre système RH, donc nul besoin de savoir ne serait-ce que les bases de codage.
            </Accordion>
            <Accordion title="Comment faire si l'on doit changer d'offre?">
              Cela se fera en quelques clics, tou simplement.
            </Accordion>
            <Accordion title="Aurais-je bseoin d'une personne en particulier qui doit s'occuper du fonctionnement de Socoopt?">
              Et bien non! notre but n'est pas de vous ajouter de la charge de travail en plus! Bien au contraire, il simplifie celui de votre responsable RH.
            </Accordion>
            <Accordion title="Pourquoi Socoopt et non pas une autre plateforme?" active>
              Le support personnalisé est vraiment adapté aux besoins de l'entreprise. En plus, l'équipe est cool donc vous allez sûrement bien vous entendre avec eux.
            </Accordion>
            {/* <Accordion title="How can I change the owner of a workspace?">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </Accordion>                                                 */}
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div >
      </div >
    </section >
  );
}

export default Faqs;
